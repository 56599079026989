import { createGlobalStyle } from 'styled-components';

export const ThemeStyles = createGlobalStyle`
  :root {
    --primary-color: #7f308a;
    --secondary-color: #909EC1;
    --terciary-color: #E8D5B5;

    --white: #ffffff;
    --light-gray: #f9fafb;
    --black: #121826;

    --font-size-sm: 0.5rem;
    --font-size-md: 1rem;
    --font-size-lg: 1.4rem;
    --font-size-xl: 2rem;
    --font-size-xxl: 3rem;
  }
`;
