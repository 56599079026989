import React from 'react';
import logo from './logo.svg';
import { AppContainer, AppHeader, AppImage, AppLink } from './App.styles';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from 'core/LanguageSelector/LanguageSelector';

export function App(): JSX.Element {
  const { t } = useTranslation();

  return (
    <AppContainer>
      <AppHeader>
        <LanguageSelector />
        <AppImage src={logo} alt="logo" />
        <p>
          {t('ACTION.EDIT')} <code>src/App.js</code> {t('ACTION.SAVE')}
        </p>
        <AppLink href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          {t('REACT_LINK')}
        </AppLink>
      </AppHeader>
    </AppContainer>
  );
}
