import styled, { keyframes } from 'styled-components';
import { NSpinner } from './Spinner.namespace';

export const SpinnerContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerBody = styled.div<NSpinner.Props>`
  border: 5px solid var(--terciary-color);
  border-left-color: transparent;
  border-radius: 50%;
  width: ${(props) => props.diameter};
  height: ${(props) => props.diameter};

  animation: ${spin} 1s linear infinite;
`;

export const SpinnerText = styled.p<NSpinner.Props>`
  font-size: ${(props) => props.fontSize};
  color: var(--terciary-color);
`;
