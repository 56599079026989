import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalStyle } from 'core/styles/global.styles';
import { NormalizeCSS } from 'core/styles/normalize.styles';
import { ResetCSS } from 'core/styles/reset.styles';
import { ThemeStyles } from 'core/styles/theme.styles';
import { AppInitLoader } from 'shared/AppInitLoader/AppInitLoader';

import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <NormalizeCSS />
    <ResetCSS />
    <ThemeStyles />
    <Suspense fallback={<AppInitLoader />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
