import React from 'react';
import { Spinner } from 'shared/Spinner/Spinner';
import { AppLoaderSpinnerContainer } from './AppInitLoader.styles';

export function AppInitLoader(): JSX.Element {
  return (
    <AppLoaderSpinnerContainer>
      <Spinner text="Neobelisco" fontSize="3rem" diameter="4rem" />
    </AppLoaderSpinnerContainer>
  );
}
