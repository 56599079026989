import { createGlobalStyle } from 'styled-components';

export const ResetCSS = createGlobalStyle`
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  h1, h2, h3, h4 {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
  }

  p {
    margin: 0;
  }
`;
