interface LanguageDetails {
  isoCode: string;
  name: string;
}

export const LanguageDetailsList: LanguageDetails[] = [
  {
    isoCode: 'en',
    name: 'English',
  },
  {
    isoCode: 'es',
    name: 'Español',
  },
  {
    isoCode: 'pt',
    name: 'Português',
  },
];
