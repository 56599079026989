import React from 'react';
import { NSpinner } from './Spinner.namespace';
import { SpinnerBody, SpinnerContainer, SpinnerText } from './Spinner.styles';

export function Spinner({ text, fontSize, diameter = '3rem' }: NSpinner.Props): JSX.Element {
  return (
    <SpinnerContainer>
      <SpinnerBody data-testid="spinner-body" diameter={diameter} />
      {text && (
        <SpinnerText data-testid="spinner-text" fontSize={fontSize}>
          {text}
        </SpinnerText>
      )}
    </SpinnerContainer>
  );
}
