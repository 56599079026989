import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageDetailsList } from './LanguageSelector.namespace';

export function LanguageSelector(): JSX.Element {
  const { i18n } = useTranslation();
  const browserLanguage = i18n.resolvedLanguage.toLowerCase();
  const [currentLanguage, setCurrentLanguage] = useState<string>(browserLanguage);

  const onLanguageChange = (changeEvent: ChangeEvent<HTMLSelectElement>) => {
    const currentLanguage = changeEvent.target.value;
    setCurrentLanguage(currentLanguage);
    i18n.changeLanguage(currentLanguage);
  };

  return (
    <select value={currentLanguage} onChange={onLanguageChange}>
      {LanguageDetailsList.map(({ isoCode, name }) => {
        return (
          <option key={isoCode} value={isoCode}>
            {name}
          </option>
        );
      })}
    </select>
  );
}
